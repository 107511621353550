<template>
  <div>
    <div class="yycg-header-list-item">
      <div class="yycg-header-list-item-view">
        <img
          class="yycg-header-img"
          src="../../assets/img/chenggong.png"
          alt=""
        />
        <div class="yycg-header-text">预约挂号成功！</div>
      </div>
    </div>
    <div class="zt-list">
      <div class="zt-list-item-view">
        <div class="zt-list-item-text">就诊人姓名</div>
        <div class="zt-list-item-text-right text-active">
          {{ datas.name }}
        </div>
      </div>
      <div class="zt-list-item-view">
        <div class="zt-list-item-text">就诊日期</div>
        <div class="zt-list-item-text-right text-active">
          {{ datas.visitDate }}
        </div>
      </div>
      <div class="zt-list-item-view">
        <div class="zt-list-item-text">就诊时间</div>
        <div class="zt-list-item-text-right text-default">
          {{ datas.visitTime }}
        </div>
      </div>
      <!-- <div class="zt-list-item-view">
        <div class="zt-list-item-text">就诊科室</div>
        <div class="zt-list-item-text-right text-default">
          {{ datas.DeptName }}
        </div>
      </div> -->
      <div class="zt-list-item-view">
        <div class="zt-list-item-text">医生</div>
        <div class="zt-list-item-text-right text-default">
          {{ datas.doctorName }}
        </div>
      </div>
      <div class="zt-list-item-view">
        <div class="zt-list-item-text">预约码</div>
        <div class="zt-list-item-text-right text-default">
          <!-- {{ datas.hisOrderId }} -->
        </div>
      </div>
      <div class="zt-list-item-view">
        <div class="zt-list-item-text">检查项目</div>
        <div class="zt-list-item-text-right text-default">挂号费</div>
      </div>
      <div class="zt-list-item-view">
        <div class="zt-list-item-text">支付单号</div>
        <div class="zt-list-item-text-right text-default">
          {{ datas.hisOrderId }}
        </div>
      </div>
      <div class="zt-list-item-view">
        <div class="zt-list-item-text">支付金额</div>
        <div class="zt-list-item-text-right text-danger">
          ￥{{ datas.amount }}
        </div>
      </div>
      <div class="zt-list-item-view">
        <div class="zt-list-item-text">支付方式</div>
        <div class="zt-list-item-text-right text-default">微信</div>
      </div>
      <div class="zt-list-item-view">
        <div class="zt-list-item-text">支付状态</div>
        <div class="zt-list-item-text-right text-default">
          {{ payState[datas.status] }}
        </div>
      </div>
    </div>
    <van-button
      style="width: 9rem; height: 50px; margin: 10px 0"
      color="#00D58B"
      plain
      hairline
      type="primary"
      to="/treatmentServices"
      >返回到首页</van-button
    >
  </div>
</template>

<script>
export default {
  data() {
    return {
      datas: {
        id: "",
        name: "",
        deptName: "",
        doctorName: "",
        hisOrderId: "",
        visitTime: "",
        visitDate: "",
        changeTime: "",
        amount: "",
        type: "",
        status: "",
      },
      payState: {
        0: "去支付",
        1: "已支付",
        2: "退款中",
        3: "已退款",
        4: "已关闭",
        5: "已取消",
      },
    };
  },
  methods: {
    getDate(date) {
      return date.split("_")[0];
    },
  },
  mounted() {
    this.$nextTick(() => {
      // 获取订单详情
      let postData = {
        id: this.$route.query.id,
      };
      this.$http.get("/api/wxappointment/info", postData).then((result) => {
        this.datas = result.data;
      });
    });
  },
};
</script>

<style scoped>
.yycg-header-list-item-view {
  display: flex;
  flex-flow: row;
  height: 90px;
  align-items: center;
  justify-content: center;
}

.yycg-header-img {
  height: 30px;
  width: 30px;
  margin-right: 10px;
}

.yycg-header-text {
  margin-left: 3px;
  font-size: 0.6rem;
  position: relative;
  top: -2px;
}

.zt-list {
  font-size: 0.5rem;
  width: 9.2rem;
  margin: auto;
  background-color: #fff;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #e6e6e6;
}

.zt-list-item-view {
  width: 100%;
  color: #afafaf;
}

.zt-list-item-text {
  width: 3rem;
  display: inline-block;
  margin: 10px 0;
  text-align: left;
}

.zt-list-item-text-right {
  width: 5rem;
  display: inline-block;
  text-align: right;
}

.text-default {
  color: #626262;
}
.text-danger {
  color: #ff0000;
}

.text-active {
  color: #2ea5ff;
}
</style>
